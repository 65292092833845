import React from 'react'
import { motion, Variants } from 'framer-motion'

export const containerVariants: Variants = {
  hidden: {
    opacity: 0,
    filter: 'blur(20px)',
    scale: 0.8,
    rotate: -5,
    y: 100,
  },
  visible: {
    opacity: 1,
    filter: 'blur(0px)',
    scale: 1,
    rotate: 0,
    y: 0,
    transition: {
      duration: 0.6,
      ease: [0.6, -0.05, 0.01, 0.99],
      repeat: Infinity,
      repeatType: 'reverse',
      repeatDelay: 1,
    },
  },
}

const AnimatedCard: React.FC = () => {
  return (
    <motion.div
      className="max-w-sm mx-auto p-6 bg-white rounded-xl shadow-lg flex flex-col items-center justify-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible">
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Animated Card</h2>
        <p className="text-gray-600">This card animates with Framer Motion.</p>
      </div>
    </motion.div>
  )
}

export default AnimatedCard
