import React, { useEffect } from 'react'
import '../../../App.css'
// import Header from '../Header/Header'
import './styles.css'
import Navbar from '../Navbar'
// import Stack from '../Stack'
import Cards from '../Cards/Cards'
import { motion } from 'framer-motion'

export const containerVariants = {
  hidden: {
    opacity: 0,
    filter: 'blur(20px)',
    scale: 0.8,
    rotate: -5,
    y: 100,
  },
  visible: {
    opacity: 1,
    filter: 'blur(0px)',
    scale: 1,
    rotate: 0,
    y: 0,
    transition: {
      duration: 1,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
}

const About: React.FC = () => {
  // Sample project data, you can replace this with your actual data

  // Function to wrap each character in a span
  const wrapTextWithSpans = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
      const text = element.innerText
      element.innerHTML = text
        .split('')
        .map((char, i) => `<span class="char" style="animation-delay:${i * 0.1}s">${char}</span>`)
        .join('')
    }
  }

  useEffect(() => {
    wrapTextWithSpans('animated-text')
  }, [])

  return (
    <div>
      {' '}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="custom-div-top"></motion.div>
      <Navbar></Navbar>
      {/* <Header title="About" description="Birds of a feather flock together" /> */}
      <motion.div initial="hidden" animate="visible" variants={containerVariants} className="description-container">
        <p className="description-block">
          I'm a creative <span className="italic"> jack-of-all-trades: </span> a designer, 3D whiz, product geek, and
          coding enthusiast.
          <span className="animated-gradient-text"> Design + Engineering</span>
        </p>
      </motion.div>
      <Cards></Cards>
      {/* <Stack></Stack> */}
      <div className="custom-div"></div>
    </div>
  )
}

export default About
