import React from 'react'
import './Resources.css'
import Navbar from '../Navbar'
import { motion } from 'framer-motion'
import { containerVariants } from '../About'
import CodeCards from '../CodeCards/CodeCards'

const Resources: React.FC = () => {
  return (
    <div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="custom-div-top"></motion.div>
      <Navbar></Navbar>
      <CodeCards></CodeCards>
      <div className="custom-div"></div>
    </div>
  )
}

export default Resources
