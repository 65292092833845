import React, { useEffect, useState } from 'react'
/* import Lottie from 'react-lottie';
import animationData from './animation.json'; // Replace 'animation.json' with the path to your Lottie animation JSON file */
import memoji from '../../../assets/memoji.webm'

interface SplashScreenProps {
  onAnimationComplete: () => void
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onAnimationComplete }) => {
  const [animationDone, setAnimationDone] = useState(false)

  useEffect(() => {
    // Simulate the Lottie animation duration (Replace 3000 with the actual duration)
    const animationDuration = 1300

    // Set a timeout to mark the animation as done after the duration
    const timeoutId = setTimeout(() => {
      setAnimationDone(true)
      onAnimationComplete() // Call the parent component's callback function
    }, animationDuration)

    return () => clearTimeout(timeoutId)
  }, [onAnimationComplete])

  return (
    <div
      style={{ height: '100vh', backgroundColor: '#0e0e0e' }}
      className={`splash-screen ${animationDone ? 'fade-out' : ''}`}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        {/* <Lottie
          options={{
            animationData: animationData,
            loop: false,
          }}
          height={500} // Adjust the height of the animation as needed
          width={500} // Adjust the width of the animation as needed
        /> */}
        {/* Use a video element to display the 'memoji' video */}
        <video autoPlay loop muted width={250} height={250}>
          <source src={memoji} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}

export default SplashScreen
