import React from 'react'
import './Cards.css'
import { motion } from 'framer-motion'
import animationProduct from '../LottieBg/lottiebg2.json'
import Lottie from 'react-lottie'

// Function to shuffle the array
const shuffleArray = array => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

const initialList = [
  {
    id: 1,
    theme: 'card-blue',
    name: 'EquipoFC',
    location: '🇪🇸',
    tech: 'Fashion',
    tech2: 'Creativity',
    path: 'https://www.instagram.com/equipofc/',
  },
  {
    id: 2,
    theme: 'card-normal',
    name: 'Reliques Atelier',
    location: '🇪🇸',
    tech: 'Cheesecake Provider',
    tech2: 'CSS',
    path: 'https://www.instagram.com/reliquesatelier/',
  },
  {
    id: 3,
    theme: 'card-black',
    name: 'Alexandra Cantallops',
    location: '🇨🇺🇪🇸',
    tech: 'Design Lead',
    path: '',
  },
  {
    id: 4,
    theme: 'card-black',
    name: 'Carlos TV',
    location: '🇪🇸🇩🇪',
    tech: 'Senior Motion Graphics Designer',
    tech2: 'Threejs',
    path: 'https://www.instagram.com/carlos___tv/?hl=en',
  },

  {
    id: 6,
    theme: 'card-normal',
    name: 'Joan Ferrer',
    location: '🇪🇸',
    tech: 'Chief Pharmaceutical Officer',
    tech2: 'CSS',
    path: '',
  },
  {
    id: 7,
    theme: 'card-blue',
    name: 'Andreu Vicente',
    location: '🇪🇸',
    tech: 'Software/ML dev and PhD of space thingies',
    path: 'https://es.linkedin.com/in/andr%C3%A9s-vicente-ar%C3%A9valo-95280615b',
  },
  {
    id: 8,
    theme: 'card-linkedin',
    name: 'Roberto C Miquel',
    location: '',
    tech: 'Senior Cretive Designer',
    path: 'https://es.linkedin.com/in/roberto-calatayud-miquel-743400145',
  },
  {
    id: 9,
    theme: 'card-green',
    name: 'Piotr Poznanski',
    location: '🇵🇱',
    tech: 'Solutions Architect / CTO',
    path: 'https://instagram.com',
  },
  {
    id: 11,
    theme: 'card-red',
    name: 'Martsattacks',
    location: '🇪🇸',
    tech: 'Tufting Artists',
    path: 'https://instagram.com/martsattacks',
  },
  {
    id: 12,
    theme: 'card-blue',
    name: 'Roger Franch',
    location: '🌎',
    tech: 'Entrepreneur👀',
    path: 'https://www.rogerfranch.com/',
  },
  {
    id: 13,
    theme: 'card-normal',
    name: 'Vicente Boluda',
    location: '🇪🇸',
    tech: 'Senior Blockchain Dev',
    path: 'https://es.linkedin.com/in/vicente-boluda-v%C3%ADas-0a03425',
  },
  {
    id: 14,
    theme: 'card-green',
    name: 'Collector FC',
    location: '🌎',
    tech: 'Football Culture',
    path: 'https://www.instagram.com/collector.fc/',
  },
  {
    id: 15,
    theme: 'card-normal',
    name: 'David Garcia',
    location: '🇪🇸',
    tech: 'Software Developer',
    path: 'https://www.davidgarciaballester.com/',
  },
  {
    id: 55,
    theme: 'card-black',
    name: 'Aurélien Gekiere',
    location: '🇧🇪',
    tech: 'Tech Lead',
    path: 'https://www.linkedin.com/in/aurelien-gekiere',
  },
  {
    id: 16,
    theme: 'card-normal',
    name: 'Ronak Parida',
    location: '🇺🇸',
    tech: 'CEO Mindless Child',
    path: 'https://www.linkedin.com/in/ronak-parida-58786613b',
  },
  {
    id: 196,
    theme: 'card-normal',
    name: 'Conspiracy Studio',
    location: '🇪🇸',
    tech: 'Design Studio',
    path: 'https://www.conspiracystudio.com/',
  },
  {
    id: 198,
    theme: 'card-black',
    name: 'Kolaps',
    location: '🇱🇧',
    tech: 'Design Studio',
    path: 'https://www.kolaps.com/en/intro/',
  },
  {
    id: 17,
    theme: 'card-blue',
    name: 'Irene Muñoz',
    location: '🇪🇸',
    tech: 'UIUX Designer',
    path: 'https://www.linkedin.com/in/irenemvc',
  },
  {
    id: 18,
    theme: 'card-green',
    name: 'Marte',
    location: '🇪🇸',
    tech: 'Design Studio',
    path: 'https://www.instagram.com/weare_marte/?hl=en',
  },
  {
    id: 19,
    theme: 'card-blue',
    name: 'Ruben Anadón',
    location: '🇪🇸',
    tech: 'Art Director',
    path: 'https://es.linkedin.com/in/rubenanadon',
  },
  {
    id: 159,
    theme: 'card-blue',
    name: 'andchill',
    location: '🌎',
    tech: '',
    path: 'https://andchill.app',
  },
]

const inspoList = [
  {
    id: 1,
    theme: 'card-black',
    name: 'Raw Materials',
    location: '',
    tech: 'Fashion',
    path: 'https://www.therawmaterials.com/',
  },
  {
    id: 2,
    theme: 'card-black',
    name: 'Emil Kowalski',
    location: '',
    tech: 'Design Engineer',
    path: 'https://emilkowal.ski/',
  },
  {
    id: 2,
    theme: 'card-black',
    name: 'shadcn/ui',
    location: '',
    tech: '',
    path: 'https://ui.shadcn.com/',
  },
]

class Cards extends React.Component {
  constructor () {
    super()
    this.state = {
      sortedList: shuffleArray(initialList.slice()),
      inspoList: shuffleArray(inspoList.slice()),
    }
  }

  render () {
    const containerVariants = {
      hidden: {
        opacity: 0,
        filter: 'blur(20px)',
        scale: 0.8,
        rotate: -10,
      },
      visible: {
        opacity: 1,
        filter: 'blur(0px)',
        scale: 1,
        rotate: 0,
        transition: {
          duration: 1,
          ease: [0.6, -0.05, 0.01, 0.99],
        },
      },
    }
    return (
      <div>
        {/* Legend */}
        <motion.div initial='hidden' animate='visible' variants={containerVariants}>
          {/* <div className='legend-container'>
            <div className='legend-item people-i-worked-with'>
              <div>People I worked with</div>
            </div>
            <div className='legend-item project-business-i-love'>
              <div>Project/Business I love</div>
            </div>
            <div className='legend-item people-i-admire'>
              <div>People I admire</div>
            </div>
            <div className='legend-item  must-check'>
              <div>Must check</div>
            </div>
          </div> */}
          <ul className='works-container'>
            <li key='1001' className='card-black'>
              <a href=''>
                <div className='works-text'>
                  <div className='works-title'>Ongoing projects ↓↓↓</div>

                  <div className='works-pills italic'>
                    <div></div>
                  </div>
                </div>
              </a>
            </li>

            <li key='1000' className='css-selector' style={{ position: 'relative' }}>
              {/* <div
                style={{
                  position: 'absolute',
                  top: '10px', // Adjust top position as needed
                  left: '10px', // Adjust left position as needed
                  width: '100px', // Adjust width as needed
                  height: '100px', // Adjust height as needed
                  zIndex: '1000',
                }}>
                <Lottie
                  options={{
                    animationData: animationProduct,
                    loop: true,
                  }}
                  height={'100%'}
                  width={'100%'}
                />
              </div> */}
              <a href='https://getgoodjobs.app'>
                <div className='works-text'>
                  <div className='works-title'>GoodJobs</div>

                  <div className='works-pills italic'>
                    <div>New Era Job Portal and ATS</div>
                  </div>
                </div>
              </a>
            </li>
            <li key='1001' className='css-selector'>
              <a href=''>
                <div className='works-text'>
                  <div className='works-title'>Blxssxm</div>

                  <div className='works-pills italic'>
                    <div>Product Design Agency</div>
                  </div>
                </div>
              </a>
            </li>
            <li key='1001' className='css-selector blxssxm'>
              <a href='https://www.instagram.com/blssm.tv'>
                <div className='works-text'>
                  <div className='works-title'>Blxssxm.tv</div>

                  <div className='works-pills italic'>
                    <div>Motion Design Agency</div>
                  </div>
                </div>
              </a>
            </li>
            <li key='10012' className='card-black'>
              <a href=''>
                <div className='works-text'>
                  <div className='works-title'>Made me who I am ↓↓↓</div>

                  <div className='works-pills italic'>
                    <div></div>
                  </div>
                </div>
              </a>
            </li>
            {this.state.sortedList.map(n => (
              <li key={n.id} className={n.theme}>
                <a href={n.path}>
                  <div className='works-text'>
                    <div className='works-title'>
                      {n.name} {n.location}
                    </div>

                    <div className='works-pills italic'>
                      <div>{n.tech}</div>
                    </div>
                  </div>
                </a>
              </li>
            ))}

            {this.state.inspoList.map(n => (
              <li key={n.id} className={n.theme}>
                <a href={n.path}>
                  <div className='works-text'>
                    <div className='works-title '>
                      <span className='animated-gradient-text'>{n.name}</span> {n.location}
                    </div>

                    <div className='works-pills italic'>
                      <div>{n.tech}</div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </motion.div>{' '}
      </div>
    )
  }
}

export default Cards
