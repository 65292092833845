import React from 'react'
import './CodeCards.css'
import { motion } from 'framer-motion'
import animationProduct from '../LottieBg/lottiebg2.json'
import Lottie from 'react-lottie'
import CodeBlock from './CodeBlock'
import AnimatedCard from './components/AnimatedCard/AnimatedCard1'

const initialList = [
  {
    id: 1,
    theme: 'c-card-blue',
    name: 'EquipoFC',
    location: '🇪🇸',
    tech: 'Fashion',
    tech2: 'Creativity',
    path: 'https://www.instagram.com/equipofc/',
    code: `
    import React from 'react'
    import { motion } from 'framer-motion'
    
    export const containerVariants = {
      hidden: {
        opacity: 0,
        filter: 'blur(20px)',
        scale: 0.8,
        rotate: -5,
        y: 100,
      },
      visible: {
        opacity: 1,
        filter: 'blur(0px)',
        scale: 1,
        rotate: 0,
        y: 0,
        transition: {
          duration: 1,
          ease: [0.6, -0.05, 0.01, 0.99],
        },
      },
    }
    
    const AnimatedCard = () => {
      return (
        <motion.div
          className="max-w-sm mx-auto p-6 bg-white rounded-xl shadow-lg flex flex-col items-center justify-center"
          style={{ filter: 'blur(0px)' }} // Apply the filter style here
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          whileHover={{ filter: 'blur(20px)' }} // Optionally animate filter on hover
        >
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Animated Card</h2>
            <p className="text-gray-600">This card animates with Framer Motion.</p>
          </div>
        </motion.div>
      )
    }
    
    export default AnimatedCard
    `,
    component: <AnimatedCard/>,
  },
  {
    id: 2,
    theme: 'c-card-green',
    name: 'EquipoFC',
    location: '🇪🇸',
    tech: 'Fashion',
    tech2: 'Creativity',
    path: 'https://www.instagram.com/equipofc/',
    code: `
    import React from 'react'
    import { motion } from 'framer-motion'
    
    export const containerVariants = {
      hidden: {
        opacity: 0,
        filter: 'blur(20px)',
        scale: 0.8,
        rotate: -5,
        y: 100,
      },
      visible: {
        opacity: 1,
        filter: 'blur(0px)',
        scale: 1,
        rotate: 0,
        y: 0,
        transition: {
          duration: 1,
          ease: [0.6, -0.05, 0.01, 0.99],
        },
      },
    }
    
    const AnimatedCard = () => {
      return (
        <motion.div
          className="max-w-sm mx-auto p-6 bg-white rounded-xl shadow-lg flex flex-col items-center justify-center"
          style={{ filter: 'blur(0px)' }} // Apply the filter style here
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          whileHover={{ filter: 'blur(20px)' }} // Optionally animate filter on hover
        >
          <div className="text-center">
            <h2 className="text-2xl font-bold mb-4">Animated Card</h2>
            <p className="text-gray-600">This card animates with Framer Motion.</p>
          </div>
        </motion.div>
      )
    }
    
    export default AnimatedCard
    `,
    component: <AnimatedCard/>,
  },
]


class CodeCards extends React.Component {
  constructor () {
    super()
    this.state = {
      sortedList: initialList.slice(),
    }
  }

  render () {
    const containerVariants = {
      hidden: {
        opacity: 0,
        filter: 'blur(20px)',
        scale: 0.8,
        rotate: -10,
      },
      visible: {
        opacity: 1,
        filter: 'blur(0px)',
        scale: 1,
        rotate: 0,
        transition: {
          duration: 1,
          ease: [0.6, -0.05, 0.01, 0.99],
        },
      },
    }
    return (
      <div className="codecards-container">
        <motion.div initial="hidden" animate="visible" variants={containerVariants}>
          <ul className="works-container">
            {this.state.sortedList.map((n) => (
              <li key={n.id} className={`${n.theme} codecard p-3 md:p-6`}>
                <div className="code-works-text mb-8">
                  <div className="works-title">
                    {n.name} {n.location}
                  </div>
                  <div className="works-pills italic">
                    <div>{n.tech}</div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row h-full w-full items-center bg-black rounded-lg overflow-hidden">
                  <div className="w-full h-1/2 md:w-[50%] md:h-full sm:h-1/3 hidden md:block">
                    <CodeBlock code={n.code} />
                  </div>
                  <div
                    style={{ backgroundColor: 'rgb(40, 42, 54)' }}
                    className="w-full h-96 md:w-[50%] md:h-full p-4 flex items-center justify-center"
                  >
                    {n.component}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </motion.div>
      </div>
    );
  }
}

export default CodeCards;