import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ code }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        alert('Code copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy code: ', err);
      });
  };

  return (
    <div className="relative h-full">
      <div className="text-xs h-full w-full overflow-y-auto rounded-md bg-gray-900">
        <button
          onClick={handleCopyClick}
          className="absolute top-2 left-2 px-3 py-1 text-xs bg-gray-50 text-gray-800 rounded-md shadow-md hover:bg-gray-700"
        >
          Copy
        </button>
        <SyntaxHighlighter language="javascript" style={dracula} className="h-full">
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeBlock;
