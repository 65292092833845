import * as React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import './global-styles.css' // Import the global styles

const colorsList = [
  '#221EFF',
  '#FFF61E',
  '#F04646',
  '#E059F5',
  '#F14397',
  '#7E32E0',
  '#BFEC5F',
  '#000000',
  '#D3B587',
  '#71B49C',
  '#FF4E02',
  '#C4BBFA',
]
// const gradient = `linear-gradient(90deg, ${colorsList.join(', ')})`

const updateAccentColor = () => {
  const randomIndex = Math.floor(Math.random() * colorsList.length)
  const selectedColor = colorsList[randomIndex]
  // Set the '--accent' color variable to the selected color
  document.documentElement.style.setProperty('--accent', selectedColor)
}

// Call the updateAccentColor function on initial load
updateAccentColor()

const rootElement = document.getElementById('root')!

createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
