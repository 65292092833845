import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

const Navbar: React.FC = () => {
  return (
    <nav className="apple-navbar">
      <div className="navbar-container">
        {/* Apple logo (You can replace with your logo image) */}
        {/* <Link to="/" className="apple-logo">
          Félix Gil
        </Link> */}

        {/* Navigation links */}
        <ul className="nav-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="https://medium.com/@flxgs" target="_blank">
              Blog
            </Link>
          </li>
          {/*   <li>
            <Link to="/development">Development</Link>
          </li> */}
          <li>
            <Link to="https://www.instagram.com/flxgs/" target="_blank">
              Lab
            </Link>
          </li>
          <li>
            <Link to="https://x.com/flxgs_" target="_blank">
              X
            </Link>
          </li>
          <li>
            <Link to="https://makerpage.me/im/flxgs" target="_blank">
              Projects
            </Link>
          </li>
          {/* <li>
            <Link to="https://foundersclub.app" target="_blank">
              Find a Co-Founder
            </Link>
          </li> */}
          <li>
            <Link className="animated-gradient-text" to="/resources">
              Resources
            </Link>
          </li>
        </ul>
        <div className="nav-links-accent-2">
          <div>hello(🥰)felixgil.es</div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
