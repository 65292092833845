import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import SplashScreen from './components/Layout/SplashScreen'

import About from './components/Layout/About'
import './global-styles.css'
import Resources from './components/Layout/Resources/Resources'

const App: React.FC = () => {
  const [animationDone, setAnimationDone] = useState(false)

  // Callback function to be called by the SplashScreen component when animation is done
  const onAnimationComplete = () => {
    setAnimationDone(true)
  }

  return (
    <Router>
      {/* Render the SplashScreen until animationDone is true */}
      {!animationDone && <SplashScreen onAnimationComplete={onAnimationComplete} />}

      <div className="app">
        <Routes>
          {/* Render other components after the SplashScreen animation is done */}
          {animationDone && (
            <>
              <Route path="/" element={<About />} />
              <Route path="/resources" element={<Resources />} /> {/* Add a route for Resources */}
            </>
          )}
        </Routes>
      </div>
    </Router>
  )
}

export default App
